<template>
  <div>
  <v-menu offset-y bottom  >
    <template v-slot:activator="{ on: {click} }">
    <v-btn plain  class="mr-0 pr-0 mr-md-1 mt-0" absolute top right @click="click" :ripple=false>
      <v-icon size=20>mdi-share-variant-outline</v-icon> <span v-if="!$vuetify.breakpoint.mobile" >分享此页面</span>
    </v-btn>
    </template>
    <v-list>
      <v-list-item link @click="copyurl">
        <v-list-item-title>复制链接</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="$util.goSina()">
        <v-list-item-title>新浪微博</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="poster" v-if="goods!=null&&uid">
        <v-list-item-title>分销海报</v-list-item-title>
      </v-list-item>
      <v-list-item link>
        <v-list-item-content>
        <v-list-item-title>
          微信扫一扫
          <v-img :src="shareImg" width="90"></v-img>
        </v-list-item-title>
        
        </v-list-item-content>
      </v-list-item>
    </v-list>

    

  </v-menu>

  <v-dialog
      v-model="dialog"
      content-class="ma-0"
      width="420"
    >
      <v-card
        color="primary"
        dark
        class="ma-0 pa-0"
      >
        <v-card-text v-if="loading">
          正在生成商品海报...
          <v-progress-linear
            :active="loading"
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
        <img :src="domain + posterImg" width="420" height="600" v-if="!loading" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import goodsApi from '@/api/goods'
import { mapState } from 'vuex'
export default {
  name: 'Item',
  data: () => ({
    dialog : false,
    loading : true,
    posterImg: '',
    shareImg : ""
  }),
  props : {
    goods : null
  },

  created() {
    this.shareImg = this.apidomain + "qrcode.php?code=" + encodeURIComponent(location.href)
  },
  computed: {
    ...mapState({
      apidomain: state => state.app.apidomain,
      domain: state => state.app.imgdomain,
      uid: state => state.user.uid,
    }),
  },
  methods: {
    copyurl () {
      //this.$util.copyClipboard(window.location.href)
      this.$copyText(window.location.href)
    },
    poster() {
      
      this.dialog = true
      goodsApi.poster({goods_id:this.goods.goods_id}).then(res => {
        if (res.data.code==1) {
          this.posterImg = res.data.path
          //this.dialog = false
          this.loading = false
        }
      })
    },
    openmenu() {
      console.log(2)
    }
  }
}
</script>

<style scoped>

</style>
