<template>
  <div  class="content mx-1 mt-5 mb-16 px-0 px-sm-16 mx-sm-12" >

    <v-card class="pa-dm-4 pb-8 pt-4" till flat>
      <v-row>
        <v-col class="pa-0" cols="12" md="6">
          <v-container v-if="!$vuetify.breakpoint.mobile">
            <v-row>
              <v-col cols="2" class="px-1">
                <v-item-group mandatory v-model="indexthumb" >
                <v-container class="thumbimages" >
                  <v-row>
                    <v-col v-for="img,k in thumbs" :key="img" cols="12">
                      <v-item v-slot="{ active, toggle }" :value="k">
                        <div
                          :class="active ? 'itemthumbactive' : ''"
                          class="itemthumb  pointer pa-2"
                          
                          
                          @click="toggle"
                        >
                          <v-img :src="domain+img"></v-img>
                        </div>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-container>
                </v-item-group>
              </v-col>
              <v-col cols="10" class="px-1 ">
                <v-img :src="domain+indeximg">
                  <v-btn v-if="!iteminfo.favorite" icon small class="favorite-icon mt-9" fab absolute top right @click.stop="addFavorite(iteminfo.goods_id)" >
                    <v-icon size=20>mdi-cards-heart-outline</v-icon>
                  </v-btn>

                  <v-btn v-if="iteminfo.favorite" icon small class="favorite-icon mt-9" fab absolute top right @click.stop="removeFavorite(iteminfo.goods_id)" >
                    <v-icon size=20>mdi-cards-heart</v-icon>
                  </v-btn>
                </v-img>
              </v-col>
            </v-row>
          </v-container>
          <v-container class="pa-0" v-if="$vuetify.breakpoint.mobile" >
            <v-carousel show-arrows-on-hover delimiter-icon="mdi-minus" height="450px" hide-delimiter-background light>
              <v-btn v-if="!iteminfo.favorite" icon small class="favorite-icon mt-9 mr-3" fab absolute top right @click.stop="addFavorite(iteminfo.goods_id)" >
                <v-icon size=20>mdi-cards-heart-outline</v-icon>
              </v-btn>
             

              <v-btn v-if="iteminfo.favorite" icon small class="favorite-icon mt-9 mr-3" fab absolute top right @click.stop="removeFavorite(iteminfo.goods_id)" >
                <v-icon size=20>mdi-cards-heart</v-icon>
              </v-btn>
              <v-carousel-item v-for="(img,i) in images" :key="i" >
                
                  <v-img  :src="domain+img"  ></v-img> 
                  
                
              </v-carousel-item>
            </v-carousel>
          </v-container>
          
        </v-col>
        <v-col class="d-flex" cols="12" md="6">
           <v-card class="pa-2 pt-md-8 " width="100%" :class="{'text-center':$vuetify.breakpoint.mobile}" till flat>
             <div class="text-h6 font-weight-black pointer" @click="$router.push('/itemlist?brand='+iteminfo.brand_id)">{{iteminfo.brand_name}}</div>
             <div class="text-md-h6 text-subtitle-1">
              {{iteminfo.goods_name}}
              <Share :goods="iteminfo" />
            </div>
            <div class="text-subtitle-2  d-flex text-md-h6 mt-md-4" :class="{'justify-center':$vuetify.breakpoint.mobile}">
              <div :class="{'text-decoration-line-through': iteminfo.discount>0}">¥{{iteminfo.price}}</div>
              <div v-if="iteminfo.discount>0" class="pl-2">¥{{iteminfo.discount_price}}</div>

            </div>
            <div class="font-weight-regular text-center text-body-2  my-2 mx-3 d-flex" :class="{'justify-center':$vuetify.breakpoint.mobile}" v-if="iteminfo.vip_price &&member_level==3">
              <v-row class="mt-2 pa-0 blackborder vipprice">
             
              <v-col class="pa-0 black white--text">VIP</v-col>
              <v-col class="pa-0">¥{{iteminfo.vip_price}}</v-col>

              </v-row>
            </div>
            
             
             <v-row class="goods-sku-select mt-4">
              <v-col class="" cols="6" md="4" v-if="skulist1!=null">
                <v-select
                  :items="skulist1.value"
                  v-model="skuvalue1"
                  item-text="spec_value_name"
                  item-value="spec_value_id"
                  :label="skulist1.spec_name"
                  
                ></v-select>
              </v-col>
              <v-col class="" cols="6" md="4" v-if="skulist2!=null">
                <v-select
                  :items="skulist2.value"
                  v-model="skuvalue2"
                  item-text="spec_value_name"
                  item-value="spec_value_id"
                  :label="skulist2.spec_name"
                  
                ></v-select>
              </v-col>
              </v-row>
              <div  class="pa-0">
                <v-btn  dark outlined  tile class="main-btn" @click="addcart">加入购物车</v-btn>
              </div>
              
              <div  class="text-left">
              <v-btn plain v-if="iteminfo.size_guide_url!=''" class="px-1 " height=26 @click="showSizeImage">
                查看尺码说明 
              </v-btn>
               </div>
              


              <div class="text-h6 font-weight-bold mt-8 text-left">商品详情</div>
              <v-divider></v-divider>
              <video width="100%" class="mt-2" controls v-if="iteminfo.goods_video_address!=''" :src="domain + iteminfo.goods_video_address"></video>
              <p class="pt-8 text-left grey--text text--darken-2" v-html="iteminfo.description">
                
                </p>
           </v-card>

        </v-col>
      </v-row>
    </v-card>

    <Likes v-if="username" />

    
    <v-dialog
      v-model="showwin"
      width="501"
    >
      <v-card class="white">
        <v-card-title class="">
          <v-btn  icon small class="favorite-icon mt-4 mr-0" fab absolute top right @click.stop="showwin=false" >
          <v-icon size=20>mdi-close</v-icon>
        </v-btn>
        </v-card-title>  
        <v-img class="mx-2 my-2" :src="domain + iteminfo.size_guide_url"></v-img>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import goodsApi from '@/api/goods'
import { mapState } from 'vuex'
import Likes from '@/components/Likes';
import Share from '@/components/Share';

export default {

  components: {
      Likes,
      Share
  },
  data: () => ({
    showwin:false,
    itemid : 0,
    iteminfo : {favorite:false},
    images : [],
    thumbs : [],
    indexthumb:0,
    
    
    skulist1 : null,
    skulist2 : null,
    skulist3 : null,
    skuvalue1 : null,
    skuvalue2 : null,
 
    //
  }),
  mounted() {
    
  },
  computed: {
    ...mapState({
      appdomain: state => state.app.domain,
      username: state => state.user.nick_name,
      domain: state => state.app.imgdomain,
      uid: state => state.user.uid,
      member_level: state => state.user.member_level,
    }),
    
    indeximg() {
      return this.images[this.indexthumb]
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        if(this.$route.query.id) {
          this.init()
        }
      }
    }
  },
  
  created() {
    //this.init()    
  },

  methods: {

    init() {
      
      let itemid = this.$route.query.id
      itemid = parseInt(itemid)

      this.images = []
      this.thumbs = []
      this.indexthumb = 0
    
    
      this.skulist1 = null
      this.skulist2 = null
      this.skulist3 = null
      this.skuvalue1 = null
      this.skuvalue2 = null

      this.itemid = itemid
      let params = {id:itemid}
      goodsApi.goodsdetail(params).then((data) => {
        this.iteminfo = data.data.goods_detail
        
        let imglist = this.iteminfo.goods_img_list
        imglist.forEach(img => {
          this.images.push(img.pic_cover_big)
          this.thumbs.push(img.pic_cover_micro)
        });

        let speclist = this.iteminfo.spec_list
        if (speclist.length>0) {
          this.skulist1 = speclist[0]

          //console.log(this.skulist1.value,this.skulist1.value[0]['spec_id'])
          if (this.skulist1.value.length==1) {
            this.skuvalue1 = this.skulist1.value[0]['spec_value_id']
          }
        }
        if (speclist.length>1) {
          this.skulist2 = speclist[1]
        }

        document.title = this.iteminfo.brand_name+" " +this.iteminfo.goods_name 

        let shareimg = this.appdomain + 'logo_icon_big.png'
        if (this.iteminfo.goods_img_list.length>0){
          shareimg = this.domain + this.iteminfo.goods_img_list[0].pic_cover_small
        }
        this.$util.weixinShare(window.location.href,document.title,shareimg)

        window.scrollTo(0,0);

      })

      /*if (this.uid>0) {
        this.$router.push({
          path: this.$route.path,
          query: Object.assign({}, this.$route.query, {source_uid: this.uid})
        })
      }*/
    },
    

    showSizeImage() {
      if (this.iteminfo.size_guide_url!=null && this.iteminfo.size_guide_url!="") {
        this.showwin = true
      }
    },

    addFavorite(id) {
      let params = {id:id}
      
      goodsApi.addfavorite(params).then(() => {
        this.iteminfo.favorite = true
        this.$message.success("已添加收藏")
      })
    },
    removeFavorite(id) {
      let params = {id:id}
      goodsApi.removefavorite(params).then(() => {
        this.iteminfo.favorite = false
        this.$message.success("已取消收藏")
      })
    },

    addcart () {
      if (this.skulist1!=null && this.skuvalue1==null) {
        this.$message.warning("请选择："+this.skulist1.spec_name)
        return
      }
      if (this.skulist2!=null && this.skuvalue2==null) {
        this.$message.warning("请选择："+this.skulist2.spec_name)
        return
      }
      let skuvalue = []
      if (this.skulist1!=null){
        skuvalue.push(this.skulist1.spec_id+":"+this.skuvalue1)
      }
      if (this.skulist2!=null){
        skuvalue.push(this.skulist2.spec_id+":"+this.skuvalue2)
      }
      let skuvalue_str = skuvalue.sort().join(";")
      let matched_sku = this.iteminfo.sku_list.filter(sku => {
        let skuitem = sku.attr_value_items
        let skuvalue_str2 = skuitem.split(";").sort().join(";")
        console.log(skuvalue_str2 +"=="+ skuvalue_str+"->"+(skuvalue_str2 == skuvalue_str))
        return skuvalue_str2 == skuvalue_str
        
      })
      if (matched_sku.length==0) {
        this.$message.warning("找不到对应的SKU")
        // log to server
        return
      }
      let skuid = matched_sku[0].sku_id

      let params = {goods_id:this.itemid,sku_id:skuid,count:1}
      goodsApi.addcart(params).then(res => {
        console.log(res)
        if (res.code==0){
          this.$message.success("已添加到购物车")
          this.$store.dispatch("user/add_cartcount",1)
        }
        else if(res.code==-401){
          
          this.$message.warning("请登录系统")
        }
        else {
          
          this.$message.warning("添加购物车失败, 请联系管理员")
        }
      })


    }
  },
};
</script>
<style scoped>
.content {
  
  position: relative;
}  
.goods-item {
  cursor: pointer;
}

.goodsthumb .swiper-slide{
  width:90px;
  opacity: 0.4;
}
.goodsthumb .swiper-slide-active{
  opacity: 1;
}
.goods-sku-select {
  min-height: 40px;
}
.itemthumbactive {
  border:1px solid black;
}
.thumbimages{
  max-height: 550px;
  overflow-y: scroll;
}
.favorite-icon {
  margin-right: -18px;
}

/deep/ .v-window__next{
  background:none !important;
}
/deep/  .v-window__prev{
  background:none !important;
}
</style>

<style lang="sass">
  @import '~vuetify/src/styles/styles.sass'

  @media #{map-get($display-breakpoints, 'md-and-up')}
    .categorybanner .v-image__image--cover
      background-position : center
    .vipprice
      margin-right:50% !important

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    .categorybanner .v-image__image--cover
      background-position : 17% !important
    .vipprice
      margin-left:25% !important
      margin-right:25% !important
  
  .v-card.on-hover.theme--dark
    background-color: rgba(#FFF, 0.8)
    >.v-card__text
      color: #000000

</style>