<template>

    <v-item class="" >
      <!--<v-hover v-slot="{ hover }" close-delay="200" >-->
        <v-card
          flat
          :ripple=false
          class="align-center goods-item pa-0"
          @click="gotoDetail(goods)"
        >     

          <v-btn v-if="goods.favorite===false" icon small class="favorite-icon mt-5 mr-0" fab absolute top right @click.stop="addFavorite(goods.goods_id)" >
            <v-icon size=20>mdi-cards-heart-outline</v-icon>
          </v-btn>

          <v-btn v-if="goods.favorite===true" icon small class="favorite-icon mt-5 mr-0" fab absolute top right @click.stop="removeFavorite(goods.goods_id)" >
            <v-icon size=20>mdi-cards-heart</v-icon>
          </v-btn>
          <v-hover v-slot="{ hover }">
          <v-card
            flat tile plain
            class="pa-1 "
          >
          <v-img
            :src="domain+goods.img"
            :aspect-ratio="3/4"
            width=247
            class=""
          >

          <v-img
            v-if="hover && goods.img2"
            :src="domain+goods.img2"
            :aspect-ratio="3/4"
            width=247
            class=""
          ></v-img>

          </v-img>
          
          </v-card>
        </v-hover>
        
          <div class="font-weight-black text-center text-subtitle-1 mb-0 mt-4">
            {{goods.brand_name}}
          </div>
          <div class="font-weight-regular text-center  text-caption text-md-subtitle-2 mb-2">
            {{goods.goods_name}}
          </div>
          <div class="font-weight-regular justify-center d-flex text-body-2 my-2">
            <div :class="{'text-decoration-line-through': goods.discount>0}">¥{{goods.price}}</div>
            <div v-if="goods.discount>0" class="pl-2">¥{{goods.discount_price}}</div>
          </div>
          <div class="font-weight-regular text-center text-body-2 my-2 mx-8 mx-md-12 vipprice" v-if="goods.vip_price &&member_level==3">
            <v-row class="ma-0 pa-0 blackborder">
              <v-col class="pa-0 black white--text">VIP</v-col>
              <v-col class="pa-0">¥{{goods.vip_price}}</v-col>
            </v-row>
            
          </div>
        </v-card>
      <!--</v-hover>-->
    </v-item>
       


</template>

<script>

import goodsApi from '@/api/goods'
import { mapState } from 'vuex'
export default {
  name: 'Item',

  props : {
    goods : {}
  },

  created() {},
  computed: {
    ...mapState({
      domain: state => state.app.imgdomain,
      uid: state => state.user.uid,
      member_level: state => state.user.member_level,
    }),
  },
  methods: {

    gotoDetail(goods) {
      let query = {id:goods.goods_id}
      if (this.uid>0) query.source_uid = this.uid
      this.$router.push( {
        path:'/itemdetail',
        query: query
      })
    },
    addFavorite(id) {
      let params = {id:id}
      
      goodsApi.addfavorite(params).then(() => {
        this.goods.favorite = true
      })
    },
    removeFavorite(id) {
      let params = {id:id}
      goodsApi.removefavorite(params).then(() => {
        this.goods.favorite = false
      })
    }
  }
}
</script>

<style scoped>
.itembrand {
  font-weight: 800;
}
.goods-item{
  width: 255px;
  margin: 0 auto;
}
.goods-item:before{
  background: none !important;
}

.vipprice{
  border-radius:unset !important;
  height:32px;
}
</style>
