<template>

    <div class="pt-6 pb-8">
      <div class="text-h6 pa-4 text-center">猜你喜欢</div>
      <v-item-group active-class="primary">
      <v-row>
        <v-col v-for="goods in likes_list" :key="goods.goods_id" cols="6" md="4">
          <Item :goods="goods" />
          <!--<v-item >
            <v-card
                elevation="0"
                     
                class="align-center goods-item"
                @click="$router.push('/itemdetail?id='+goods.goods_id+'')"
              >     

                
                <v-card
                  flat tile plain
                >
                <v-img
                  :src="domain+goods.pic_cover_mid"
                  aspect-ratio="0.87"
                  max-height=330
                  class=""
                >
                  
                </v-img>
                
                </v-card>
             
              
                <div class="font-weight-black text-center  mb-2 mt-4">
                  {{goods.brand_name}}
                </div>
                <div class="font-weight-regular text-center  text-caption text-md-subtitle-1 mb-2">
                  {{goods.goods_name}}
                </div>
                <div class="font-weight-regular text-center  text-body-2 my-2">
                  ¥{{goods.price}}
                </div>
              </v-card>
          </v-item>-->
        </v-col>
      </v-row>
      </v-item-group>
    </div>

</template>

<script>

import goodsApi from '@/api/goods'
import { mapState } from 'vuex'
import Item from '@/components/Item';
export default {
  name: 'Likes',
  components: {
    Item
  },

  data: () => ({
    likes_list : []
      

  }),
  
  created() {
    this.getlist()
  },
  computed: {
    ...mapState({
      domain: state => state.app.imgdomain,
    }),
  },
  methods: {
    getlist() {
      goodsApi.likes({page_size:3}).then(res => {
        
        res.data.data.forEach(item => {
          item.img = item.pic_cover_mid
          
          this.likes_list.push(item)
        })
        //this.likes_list = res.data.data
      })
      
    },
    
  }
}
</script>

<style scoped>

</style>
